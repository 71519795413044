import ImageGallery from '@/components/Shared/ImageGallery/ImageGallery.vue'
import clientService from '@/services/queries/clientQueries'

export default {
  components: {
    ImageGallery,
  },

  data() {
    return {
      images: [],
      isClientTransient: false
    }
  },

  metaInfo() {
    return {
      title: this.$t('agent.clients.gallery.pageTitle')
    }
  },

  created() {
    clientService.find(this.$route.params.id).then(data => {
      this.isClientTransient = data.client.transient
    })

    this.getClientGallery()
  },

  methods: {
    getClientGallery() {
      clientService.getGallery(this.$route.params.id).then(data => {
        this.images = data
      })
    },

    addImage(files) {
      let imageData = files[0]
      let formData = new FormData

      formData.append('image', imageData)

      clientService.storeGalleryImage({id: this.$route.params.id, image: formData}).then(data => {
        this.images.push(data)
      })
    },

    removeImage(imageId) {
      clientService.removeGalleryImage({clientId: this.$route.params.id, imageId}).then(data => {
        let imageIndex = this.images.findIndex(img => img.image_id === imageId)

        this.images.splice(imageIndex, 1)
      })
    }
  }
}